import React, { useContext } from 'react';
import { ThemeContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import PropTypes from 'prop-types';

import { CgvViewWrapper } from 'ToolboxComponents/webapp/pages/cgv-view/cgv-view.styles';

const CgvView = ( {infos} ) => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const { email } = infos;
  return (
    <>
     {language === 'fr' ? 
      <CgvViewWrapper theme={theme}>
        <h2>CGV - Clause de protection des données personnelles</h2>
    
        <br />
    
        <p>
          Les données nominatives fournies par l’Utilisateur sur la Plateforme (profil, création de compte administrateur, logs de connexion…) sont nécessaires à la bonne gestion du Service, à la publication de contenus pour et par l’Utilisateur et à l’exploitation des informations sur les utilisateurs finaux. Les données des Utilisateurs ne sont transférées à des tiers que sous réserve d’avoir informé et/ou avoir le obtenu le consentement des Utilisateurs. Ces données sont conservées pour la durée de la relation contractuelle. Le traitement des informations communiquées a fait l'objet d'une déclaration auprès de la CNIL.
        </p>
    
        <br />
    
        <p>
          Conformément aux dispositions légales relatives à la protection des données à caractère personnel, l’Utilisateur dispose notamment d'un droit d'accès, de rectification et d'opposition pour motif légitime s'agissant des informations le concernant et du droit d’organiser le sort de ses données post-mortem. Ces droits s’exercent par email à <a href={`mailto:${email}`}>{email}</a> en précisant dans l’objet « MES DONNEES » et en joignant la copie d’un justificatif d’identité. Pour en savoir plus consultez notre <Anchor code='privacy'>Politique de confidentialité</Anchor>.
        </p>
      </CgvViewWrapper>: 
      <CgvViewWrapper theme={theme}>
        <h2>General Conditions of Sale - Personal data protection clause</h2>
      
        <br />
      
        <p>
        The personal data provided by the User on the Platform (profile, creation of an administrator account, connection logs, etc.) is necessary for the smooth operation of the Service, for the publication of content for and by the User and for the use of information on end users. Users' data is only transferred to third parties subject to having informed and/or obtained the consent of Users. This data is kept for the duration of the contractual relationship. The processing of information communicated has been declared to the CNIL.
        </p>
    
        <br />
    
        <p>
          In accordance with the legal provisions relating to the protection of personal data, the User has in particular a right of access, rectification and opposition on legitimate grounds with regard to the information concerning them and the right to organize the fate of their post-mortem data. These rights may be claimed by email sent to  <a href={`mailto:${email}`}>{email}</a>  specifying in the subject "MY DATA" and attaching a copy of proof of identity. To learn more, see our  <Anchor code='privacy'>Privacy Policy</Anchor>.
        </p>
      </CgvViewWrapper> 
     } 
    
    </>
    
  );
}

CgvView.propTypes = {
  infos: PropTypes.object.isRequired
}

export default CgvView;
